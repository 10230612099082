import { useState, useEffect } from 'react'
import Link from 'next/link'
import Btn from 'components/btn/btn'
import BtnGroup from 'components/btn-group/btn-group'
import Heading from 'components/heading/heading'
import Checkbox from 'components/input/checkbox/checkbox'
import styles from './cookies.module.scss'
import * as fbq from 'utils/analytics/fpixel'
import * as gtag from 'utils/analytics/gtag'
import { getRoute } from 'utils/helpers'
import Cookies from 'universal-cookie'

export default function CookiesCom({ showCookie, setShowCookie }) {
    const [step, setStep] = useState(1)
    const cookies = new Cookies()
    const [session, setSession] = useState(0)
    const [settings, setSettings] = useState({
        required: 1,
        analytics: 0,
        marketing: 0
    })

    const policyUrl = getRoute('cookie-policy')
    const doNotShow = typeof window != 'undefined' && window.location.pathname === '/uzsakymas-priimtas'
    const dev = typeof window != 'undefined' && window.location.search.indexOf('devmode') > -1

    useEffect(() => {
        const agreeCookies = cookies.get('agreeCookies')
        setSession(agreeCookies)
    }, [])

    const handleChange = e => {
        const name = e.target.name
        const value = e.target.checked ? 1 : 0
        setSettings({ ...settings, [name]: value })
    }

    const setCookieHandler = type => {
        let val = ['r']
        if (type == 'checked') {
            if (settings.analytics) val.push('a')
            if (settings.marketing) val.push('m')
        } else {
            val = ['r', 'a', 'm']
        }

        cookies.set('agreeCookies', val, { path: '/', expires: new Date(Date.now() + 31536000000) })
        setShowCookie(false)

        if (val.indexOf('m') > -1) {
            fbq.grant()
            gtag.grantAd()
        }
        if (val.indexOf('a') > -1) {
            gtag.grantAnalytics()
        }
    }

    if (!showCookie || session || session === 0 || dev || doNotShow) {
        return null
    }

    return (
        <div className={styles.cookiesPopup}>
            <div className={styles.cookiesWrapper}>
                <div className={styles.cookiesContainer}>
                    {step === 1 ? (
                        <>
                            <Heading tag="div" size="30" className="mb18">
                                Mes naudojame slapukus
                            </Heading>
                            <div className={styles.cookiesText}>
                                Slapukai padeda užtikrinti tinkamą tinklapio veikimą bei jo tobulinimą, todėl būtinieji
                                slapukai įdiegiami automatiškai. Siekiant pateikti pritaikytą informaciją bei
                                pasiūlymus, kurie būtų aktualūs būtent Jums, taip pat naudojami tiksliniai slapukai. Jei
                                sutinkate, prašome paspausti mygtuką „Sutinku su visais slapukais“. Jei nenorite duoti
                                sutikimo ar norite pakeisti slapukų nustatymus, tai galite padaryti{' '}
                                <span className={styles.cookiesLink} onClick={() => setStep(2)}>
                                    slapukų nustatymuose
                                </span>
                                . Jūsų slapukų nustatymų pasirinkimai bus saugomi vienus metus. Plačiau skaitykite{' '}
                                <Link href={policyUrl}>
                                    <a onClick={() => setShowCookie(false)} className={styles.cookiesLink}>
                                        slapukų politikoje
                                    </a>
                                </Link>
                                .
                            </div>
                            <Btn style="yellow" block onClick={() => setCookieHandler('all')}>
                                Sutinku
                            </Btn>
                        </>
                    ) : (
                        <>
                            <Heading tag="div" size="30" className="mb18">
                                Slapukų nustatymai
                            </Heading>
                            <div className={styles.cookiesText}>
                                Pasirinkite, kuriuos slapukus sutinkate priimti. Jūsų slapukų nustatymų pasirinkimai bus
                                saugomi vienus metus. Galite atsisakyti slapukų įrašymo ar ištrinti juos iš savo
                                naršyklės. Plačiau skaitykite{' '}
                                <Link href={policyUrl}>
                                    <a className={styles.cookiesLink}>slapukų politikoje</a>
                                </Link>
                                .
                            </div>
                            <Checkbox
                                name="required"
                                value={settings.required}
                                onChange={handleChange}
                                readOnly={1}
                                label="Būtinieji"
                            />
                            <Checkbox
                                name="analytics"
                                value={settings.analytics}
                                onChange={handleChange}
                                label="Analitiniai"
                            />
                            <Checkbox
                                name="marketing"
                                value={settings.marketing}
                                onChange={handleChange}
                                label="Rinkodaros"
                            />
                            <BtnGroup fill>
                                <Btn style="light" onClick={() => setCookieHandler('checked')}>
                                    Išsaugoti pasirinkimą
                                </Btn>
                                <Btn style="yellow" onClick={() => setCookieHandler('all')}>
                                    Sutinku su visais
                                </Btn>
                            </BtnGroup>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}
