import { cn } from 'utils/helpers'
import styles from './btn-group.module.scss'

export default function BtnGroup({ children, withBorder, withBlanks, fill, align }) {
    return (
        <div
            className={cn(
                styles.group,
                withBorder && styles.withBorder,
                withBlanks && styles.withBlanks,
                fill && styles.fill,
                align && styles[align]
            )}
        >
            {children}
        </div>
    )
}
