import Info from 'components/info/info'
import Svg from 'components/svg/svg'
import styles from './checkbox.module.scss'
import { cn } from 'utils/helpers'

export default function Checkbox({
    className,
    readOnly,
    value,
    onChange,
    required,
    moreLink,
    name,
    label,
    info,
    description
}) {
    const useValue = value == 'on' ? 1 : value || ''
    return (
        <div className={cn(styles.checkbox, readOnly && styles.readonly, className)}>
            <label>
                <input
                    type="checkbox"
                    value={useValue}
                    required={required}
                    name={name}
                    checked={!!value}
                    onChange={onChange}
                    readOnly={readOnly}
                />
                <i>
                    <Svg name="check" />
                </i>
                <span>
                    {label}
                    {info && <Info text={info} />}
                    {moreLink && (
                        <>
                            {` `}
                            <a rel="noreferrer noopener" href={moreLink} target="_blank">
                                Plačiau
                            </a>
                        </>
                    )}
                </span>
                {description && <small className={styles.description}>{description}</small>}
            </label>
        </div>
    )
}
